<template>
  <div
    class="themelogout"
    :style="{ background: $store.getters.getColor('bgColorSecundary') }"
  >
    <v-container class="fill-height pa-8">
      <v-row no-gutters class="mb-0">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <img
            v-if="$store.getters.getLogoClaro"
            :src="$store.getters.getLogoClaro"
            width="120"
            class="mx-4"
          />
          <h1 class="white--text">{{ $store.getters.getAppName }}</h1>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="8">
          <validation-observer ref="form">
            <v-form @submit.prevent="submit()" autocomplete="off">
              <v-row v-if="temCredenciais">
                <v-col cols="7">
                  <validation-provider v-slot="{ errors }" name="Usuário" rules="required">
                    <v-text-field
                      :error-messages="errors"
                      v-model="form.username"
                      name="usuario"
                      :data-test-id="'usuario'"
                      label="Usuário"
                      maxlength="200"
                      :disabled="temCredenciais && dispositivoMovel"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col>
                  <v-btn @click="apagarCredenciais" block large :data-test-id="'mudar'" color="white" class="text-none mt-5 font-weight-bold">
                    <b :data-test-id="'mudar'" class="primary--text">Mudar</b>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" name="Senha" rules="required">
                    <v-text-field
                      name="password"
                      v-model="form.password"
                      :data-test-id="'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      label="Senha"
                      maxlength="100"
                      :error-messages="errors"
                      @click:append="showPassword = !showPassword"
                      required
                    ></v-text-field>
                    <div class="white--text text-right cursor-pointer mt-n3 pr-1 esqueci-senha__button" @click="abrirResetSenha()">Esqueceu sua senha?</div>
                  </validation-provider>
                </v-col>
              </v-row>
              <div v-else>
                <validation-provider v-slot="{ errors }" name="Usuário" rules="required">
                  <v-text-field
                    :error-messages="errors"
                    v-model="form.username"
                    name="usuario"
                    :data-test-id="'usuario'"
                    label="Usuário"
                    maxlength="200"
                    :disabled="temCredenciais && dispositivoMovel"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="Senha" rules="required">
                  <v-text-field
                    name="password"
                    v-model="form.password"
                    :data-test-id="'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    label="Senha"
                    maxlength="100"
                    :error-messages="errors"
                    @click:append="showPassword = !showPassword"
                    required
                  ></v-text-field>
                  <div class="white--text text-right cursor-pointer mt-n3 pr-1 esqueci-senha__button" @click="abrirResetSenha()">Esqueceu sua senha?</div>
                </validation-provider>
              </div>
              <v-btn block large :data-test-id="'entrar'" type="submit" elevation="10" color="white" class="text-none mb-10 mt-10 font-weight-bold" :loading="$store.getters.getLoadingGlobal">
                <b :data-test-id="'entrar'" class="primary--text">Entrar</b>
                <span slot="loader">
                  <v-progress-circular indeterminate :width="3" color="primary"></v-progress-circular>
                </span>
              </v-btn>
              <v-row v-if="temCredenciais" justify="center">
                <v-btn :rounded="true" class="mb-10" @click="entrarComDigital">
                  <v-icon color="black">mdi-fingerprint</v-icon>
                  <b class="primary--text mt-1">Entrar com digital</b>
                </v-btn>
              </v-row>
            </v-form>
            <div class="d-flex justify-center">
              <v-btn text :data-test-id="'cadastrar'" large :to="{ name: 'welcome' }" color="white" class="text-none font-weight-bold primary--text">Cadastrar-se</v-btn>
            </div>
          </validation-observer>
        </v-col>
      </v-row>
      <v-spacer style="width: 100%"></v-spacer>
    </v-container>

    <v-dialog class="esqueci-senha" v-model="redefinirSenha.dialog" width="320">
      <v-card>
        <v-card-title class="primary--text pt-6"
          >Esqueci minha senha</v-card-title
        >
        <v-card-text class="esqueci-senha__conteudo">
          <div class="esqueci-senha__form p-">
            <validation-observer ref="esqueciSenha">
              <base-text-field
                rules="required|cpf"
                id="cpf"
                inputmode="numeric"
                name="CPF"
                v-model="redefinirSenha.cpf"
                label="CPF *"
                v-mask="'###.###.###-##'"
                clearable
                ref="cpf"
              ></base-text-field>
              <base-text-field
                type="email"
                rules="required|email"
                id="email"
                name="E-mail"
                v-model="redefinirSenha.email"
                label="E-mail *"
                clearable
              ></base-text-field>
              <div class="d-flex justify-end mt-1">
                <v-btn
                  color="grey darken-1"
                  text
                  @click="redefinirSenha.dialog = false"
                >
                  cancelar
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="resetarSenha()"
                  :loading="redefinirSenha.loading"
                >
                  enviar
                </v-btn>
              </div>
            </validation-observer>
          </div>
          <div
            class="esqueci-senha__sucesso"
            :class="{
              'esqueci-senha__sucesso--mostrar': redefinirSenha.sucesso,
            }"
          >
            <div class="text-body-1">
              Sua senha foi redefinida.<br />
              A sua nova senha foi enviada para
              <strong>{{ redefinirSenha.email }}</strong
              >.
            </div>
            <v-btn
              depressed
              color="primary"
              @click="redefinirSenha.dialog = false"
              class="mt-10 float-right"
              >ok</v-btn
            >
          </div>
          <!--div class="esqueci-senha__usuario-interno">
            Desculpe, não é possivel resetar senha.
          </div-->
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="logo-novo-dark"></div>
    <div class="logo-novo-light"></div>
  </div>
</template>

<script>
/* eslint-disable */
import usuarioService from "@/services/usuarioService";
import corretoraService from "@/services/corretoraService";
import BaseTextField from "@/components/BaseTextField.vue";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";
import {
  BiometricAuth,
} from '@aparajita/capacitor-biometric-auth'
import { SecureStorage } from '@aparajita/capacitor-secure-storage'
import { Capacitor } from '@capacitor/core'

export default {
  name: "IndexLogin",
  data() {
    return {
      auth: null,
      showPassword: false,
      temCredenciais: false,
      autenticacaoDigital: false,
      redefinirSenha: {
        dialog: false,
        cpf: "",
        email: "",
        sucesso: false,
        loading: false,
      },
      form: {
        username: "",
        password: "",
      },
      unwatch: null,
      keyPrefix: null,
    };
  },
  components: { BaseTextField },
  computed: {
    ...mapState({
      userState: (state) => state.user.profile,
    }),
    dispositivoMovel: () => Capacitor.isNativePlatform(),
  },
  created() {
    // Ouvinte de objeto termosUso
    // Caso callback seja invocado, submit é execitado
    this.unwatch = this.$store.watch(
      (state, getters) => getters.getTermoUso,
      (newValue, oldValue) => {
        if (newValue && newValue.callback && this[newValue.callback]) {
          this[newValue.callback]();
          this.$store.commit("SET_CALLBACK_TERMO");
        }
      },
      {
        deep: true, // Identifica alteração em uma propriedade
      },
    );

    if(Capacitor.isNativePlatform()) {
      this.verficaCredenciais();
    }
  },
  beforeDestroy() {
    if (this.unwatch) { this.unwatch(); }
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async submit() {
      this.$store.commit("SET_CALLBACK_TERMO", '');
      if(!this.autenticacaoDigital) {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
      }
      this.autenticacaoDigital = false;
      this.$store.commit("SET_LOADING_GLOBAL", true);
      try {
        const { cpf } = await this.login(this.form);
        this.$store.commit("SET_LOADING_GLOBAL", false);

        const dataUser = await this.carregaDadosCorretor(cpf);
        if (isEmpty(dataUser)) throw new Error("Erro ao caregar dados do corretor");

        const { flagConfirmacaoDados, tipoCorretor, subTipoCorretor } = dataUser;

        if(Capacitor.isNativePlatform()) {
          this.setCredenciais(this.form);
        }

        if (!flagConfirmacaoDados && tipoCorretor !== "Interno" && subTipoCorretor !== "PME" && subTipoCorretor !== "Atendimento") {
          // Altera tentativa de confirmação de dados
          await usuarioService.addTentativaConfirmacaoDados({ cpf });
          this.$router.push({ name: "areaLogada.confirmacaoDados", query: { login: true } });
        } else if (this.$route.query && this.$route.query.path) this.$router.push(this.$route.query);
        else this.$router.push("/");
      } catch (error) {
        if((error.status && error.status === 307) && (error.data && error.data.tipoCorretor && error.data.tipoCorretor === "Externo") ) {
          sessionStorage.setItem("corretorpw", this.form.password)
          sessionStorage.setItem("corretoremail", this.form.username)
          sessionStorage.setItem("corretorcpf", error.data.cpf)
          sessionStorage.setItem("corretoremaildestinatario", error.data.email)
          sessionStorage.setItem("corretorsms", error.data.destinatarioSms)
          this.$root.$snackBar.open({ color: "error", message: "Por favor, valide o token enviado por email/SMS" });
          this.$router.push("/verificacao")
        } else this.$root.$snackBar.open({ color: "error", message: error.message });
        this.$store.commit("SET_LOADING_GLOBAL", false);
      }
    },

    async autenticarComBiometria(){
      // Se não está rodando em um dispositivo móvel
      if(!Capacitor.isNativePlatform()) return false;

      const {isAvailable} = await BiometricAuth.checkBiometry();

      // Se não houver biometria disponível
      if (!isAvailable) return false;

      try {
        await BiometricAuth.authenticate({
          reason: "Lembre-se que qualquer pessoa com biometria cadastrada no dispositivo terá acesso",
          cancelTitle: "Cancelar",
          androidTitle: "Autenticação",
          androidSubtitle: "Para desativar acesse Configurações > Biometria"
        });
        this.autenticacaoDigital = true;
        return true;
      } catch (error) {
        throw new Error("Autenticação cancelada!");
      }
    },

    async entrarComDigital() {
      try {
        if(await this.autenticarComBiometria()) {
          this.form = await SecureStorage.get(this.keyPrefix);
          this.submit();
        }
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      }
    },

    abrirResetSenha() {
      this.redefinirSenha.sucesso = false;
      this.redefinirSenha.dialog = true;
      this.redefinirSenha.loading = false;
    },
    async resetarSenha() {
      const isValid = await this.$refs.esqueciSenha.validate();
      if (!isValid) return;
      const { cpf, email } = this.redefinirSenha;
      if (
        email.includes("qualicorp.com.br") ||
        email.includes("fornecedor.qualicorp.com.br")
      ) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Não é possivel redefinir essa senha.",
        });
        return;
      }
      this.redefinirSenha.loading = true;
      try {
        const data = await usuarioService.novaSenha(cpf, email);
        this.redefinirSenha.sucesso = true;
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      } finally {
        this.redefinirSenha.loading = false;
      }
    },
    async carregaDadosCorretor(cpf) {
      try {
        const dataUser = await usuarioService.buscarCorretor({ cpf });
        const corretoras = await corretoraService.getCorretorasPorCpf({ cpf });
        this.$store.dispatch("user/setUserData", { ...dataUser, corretoras }, { root: true });
        const { data: urlFotoPerfil } = await usuarioService.getFotoPerfil();
        this.$store.dispatch("user/setUrlFotoPerfil", urlFotoPerfil);
        return dataUser;
      } catch (error) {
        localStorage.removeItem(`${process.env.VUE_APP_NAME_AUTH}`);
        return {};
      }
    },
    async verficaCredenciais() {
      await this.setKeyPrefix();
      const data = await SecureStorage.get(this.keyPrefix);
      this.temCredenciais = data ? true : false;
      if(this.temCredenciais) {
        this.form.username = data.username;
      }
    },
    async setCredenciais(form) {
      await SecureStorage.set(this.keyPrefix, form);
    },
    async setKeyPrefix() {
      this.keyPrefix = 'capacitor-storage_qualivendas_';
      await SecureStorage.setKeyPrefix(this.keyPrefix);
    },
    async apagarCredenciais() {
      await SecureStorage.clear();
      this.temCredenciais = false;
      this.form.username = null;
    }
  },
  
};
</script>
<style lang="scss" scoped>
.modal-title {
  word-break: break-word;
}
.esqueci-senha__button {
  position: relative;
  z-index: 2;
  font-size: 0.85rem;
  letter-spacing: 0.06rem;
}
.esqueci-senha__conteudo {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.esqueci-senha__sucesso {
  position: absolute;
  top: 100%;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  padding: 20px;
  transition: top 0.5s ease;
}

.esqueci-senha__sucesso--mostrar {
  top: 0;
}

.card-visualizar-termos {
  padding-top: 40px !important;
}
</style>
